export const authPages = {
  signIn: "/login",
  verifyRequest: "/email-sent",
};
export const publicPages = [
  "/login",
  "/logout",
  "/register",
  "/email-sent",
  "/live",
];
