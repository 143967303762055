import { Game, Prisma, User } from "database";
import {
  CurrentGameResponse,
  ICard,
  IGame,
  IGamePhase,
  IGameRound,
} from "./types";

/**
 * User
 */
export const fetchCurrentUser = (): Promise<User> => {
  return fetch(`/api/me`).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
};

/**
 * Game
 */

export const fetchCurrentGame = (): Promise<CurrentGameResponse> => {
  return fetch(process.env.NEXT_PUBLIC_LIVE_JSON || "", {
    cache: "no-store",
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
};

export const fetchCurrentGameLive = (): Promise<{
  lastNumber?: number;
}> => {
  return fetch(`/api/games/current/live`).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
};

export const fetchGames = (): Promise<IGame[]> => {
  return fetch(`/api/games`).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
};

export const fetchGameById = (id: string): Promise<IGame> => {
  return fetch(`/api/games/${id}`).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
};

export const createGame = (): Promise<IGame> => {
  return fetch(`/api/games`, {
    method: "POST",
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
};

export function updateGame(gameId, body) {
  return fetch(`/api/games/${gameId}`, {
    method: "PATCH",
    body: JSON.stringify(body),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function setNextPhaseGame(gameId) {
  return fetch(`/api/games/${gameId}/next-phase`, {
    method: "POST",
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function setPrevPhaseGame(gameId) {
  return fetch(`/api/games/${gameId}/prev-phase`, {
    method: "POST",
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

/**
 * GameRound
 */

export function selectGameRoundNumber(gameRoundId, number) {
  return fetch(`/api/gamerounds/${gameRoundId}/select-number`, {
    method: "POST",
    body: JSON.stringify({ number }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function updateGameRound(gameRoundId, body) {
  return fetch(`/api/gamerounds/${gameRoundId}`, {
    method: "PATCH",
    body: JSON.stringify(body),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

/**
 * GamePhase
 * */

export function updateGamePhase(gamePhaseId, body) {
  return fetch(`/api/gamephases/${gamePhaseId}`, {
    method: "PATCH",
    body: JSON.stringify(body),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

/**
 * GameSpeed
 * */

export function createGameSpeed(body) {
  return fetch(`/api/gamespeeds`, {
    method: "POST",
    body: JSON.stringify(body),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function updateGameSpeed(gameSpeedId, body) {
  return fetch(`/api/gamespeeds/${gameSpeedId}`, {
    method: "PATCH",
    body: JSON.stringify(body),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

/**
 * User
 */

export function fetchUserCards(gameId: string): Promise<ICard[]> {
  return fetch(`/api/games/${gameId}/user-cards`).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function generateUserCards(
  gameId: string,
  force = false
): Promise<ICard[]> {
  return fetch(`/api/games/${gameId}/user-cards`, {
    method: "POST",
    body: JSON.stringify({ force }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function updateCurrentUser(data) {
  return fetch(`/api/me`, {
    method: "PATCH",
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

/**
 * Winner
 */

export function createWinner(gameId) {
  return fetch(`/api/games/${gameId}/win`, {
    method: "POST",
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function createSpeedWinner(speedId) {
  return fetch(`/api/gamespeeds/${speedId}/win`, {
    method: "POST",
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function fetchWinnerCards(winnerId: string): Promise<ICard[]> {
  return fetch(`/api/winners/${winnerId}/cards`).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}

export function updateWinner(winnerId, data) {
  return fetch(`/api/winners/${winnerId}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }

    return res.json();
  });
}
