"use client";

import { AuthProvider } from "../../contexts/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SessionProvider } from "next-auth/react";
import { AlertDialogProvider, Toaster } from "ui";

const queryClient = new QueryClient();

export function Providers({ children }) {
  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AlertDialogProvider>{children}</AlertDialogProvider>
        </AuthProvider>
      </QueryClientProvider>
      <Toaster />
    </SessionProvider>
  );
}
