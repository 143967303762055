"use client";
import { fetchCurrentUser } from "../lib/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { User } from "database";
import { signOut, useSession } from "next-auth/react";
import { redirect, usePathname, useRouter } from "next/navigation";
import { createContext, useContext, useEffect } from "react";
import { publicPages } from "../config/auth";
import { is } from "date-fns/locale";

export type AuthContextType = {
  user?: User;
  isLoading: boolean;
};

const defaultState = {
  isLoading: false,
};

export const AuthContext = createContext<AuthContextType>(defaultState);

export const AuthProvider = ({ children }) => {
  const { status, data } = useSession();

  const sessionUser = data?.user;
  const router = useRouter();
  const pathname = usePathname();

  const isLoadingSession = status === "loading";
  useEffect(() => {
    if (status === "unauthenticated" && !publicPages.includes(pathname)) {
      router.replace(`/login`);
    }
  }, [pathname, status, router]);

  const {
    data: user,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["me"],
    queryFn: fetchCurrentUser,
    enabled: !!sessionUser,
    initialData: sessionUser,
  });

  if (isError) {
    signOut();
  }

  if (isLoadingSession) {
    return <></>;
  }

  if (user) {
    if (pathname.startsWith("/admin") && user.role !== "admin") {
      redirect("/");
    } else if (!pathname.startsWith("/admin") && user.role === "admin") {
      redirect("/admin");
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
